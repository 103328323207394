@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?1t1h6b');
  src:  url('fonts/icomoon.eot?1t1h6b#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?1t1h6b') format('truetype'),
    url('fonts/icomoon.woff?1t1h6b') format('woff'),
    url('fonts/icomoon.svg?1t1h6b#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="rmv-icon-"], [class*=" rmv-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rmv-icon-fact_check:before {
  content: "\e961";
}
.rmv-icon-manufacturing:before {
  content: "\e960";
}
.rmv-icon-globe:before {
  content: "\e95f";
}
.rmv-icon-clear-all:before {
  content: "\e945";
}
.rmv-icon-domain:before {
  content: "\e944";
}
.rmv-icon-export:before {
  content: "\e943";
}
.rmv-icon-email-bulk:before {
  content: "\e942";
}
.rmv-icon-sms:before {
  content: "\e93e";
}
.rmv-icon-call:before {
  content: "\e93f";
}
.rmv-icon-mail:before {
  content: "\e940";
}
.rmv-icon-note:before {
  content: "\e941";
}
.rmv-icon-reverse:before {
  content: "\e93d";
}
.rmv-icon-task:before {
  content: "\e937";
}
.rmv-icon-pin:before {
  content: "\e935";
}
.rmv-icon-move:before {
  content: "\e932";
}
.rmv-icon-pencil:before {
  content: "\e933";
}
.rmv-icon-switch-cluster:before {
  content: "\e934";
}
.rmv-icon-menu-vertical:before {
  content: "\e931";
}
.rmv-icon-save:before {
  content: "\e92e";
}
.rmv-icon-trash:before {
  content: "\e92f";
}
.rmv-icon-search:before {
  content: "\e930";
}
.rmv-icon-optimize:before {
  content: "\e92d";
}
.rmv-icon-thin-arrow-down:before {
  content: "\e929";
}
.rmv-icon-thin-arrow-up:before {
  content: "\e92a";
}
.rmv-icon-list:before {
  content: "\e927";
}
.rmv-icon-map:before {
  content: "\e928";
}
.rmv-icon-address:before {
  content: "\e924";
}
.rmv-icon-open-conversation:before {
  content: "\e923";
}
.rmv-icon-ic-time-end:before {
  content: "\e921";
}
.rmv-icon-ic-time-start:before {
  content: "\e922";
}
.rmv-icon-logout:before {
  content: "\e920";
}
.rmv-icon-menu:before {
  content: "\e91e";
}
.rmv-icon-ic-check:before {
  content: "\e91d";
}
.rmv-icon-ic-plus:before {
  content: "\e91c";
}
.rmv-icon-ic-pin:before {
  content: "\e900";
}
.rmv-icon-ic-filters:before {
  content: "\e901";
}
.rmv-icon-ic-users:before {
  content: "\e902";
}
.rmv-icon-ic-user:before {
  content: "\e903";
}
.rmv-icon-ic-settings:before {
  content: "\e904";
}
.rmv-icon-ic-search:before {
  content: "\e905";
}
.rmv-icon-ic-phone:before {
  content: "\e906";
}
.rmv-icon-ic-messages:before {
  content: "\e907";
}
.rmv-icon-ic-location:before {
  content: "\e908";
}
.rmv-icon-ic-info:before {
  content: "\e909";
}
.rmv-icon-ic-flag:before {
  content: "\e90a";
  color: #1e2e50;
}
.rmv-icon-ic-edit:before {
  content: "\e90b";
}
.rmv-icon-ic-drop:before {
  content: "\e90c";
}
.rmv-icon-ic-documents:before {
  content: "\e90d";
}
.rmv-icon-ic-search1:before {
  content: "\e90e";
}
.rmv-icon-ic-settings1:before {
  content: "\e90f";
}
.rmv-icon-ic-user1:before {
  content: "\e910";
  color: #1e2e50;
}
.rmv-icon-ic-date_range:before {
  content: "\e911";
}
.rmv-icon-ic-assignment_turned_in:before {
  content: "\e912";
}
.rmv-icon-ic-class:before {
  content: "\e913";
}
.rmv-icon-ic-close:before {
  content: "\e914";
}
.rmv-icon-ic-dashboard:before {
  content: "\e915";
}
.rmv-icon-ic-archive:before {
  content: "\e916";
}
.rmv-icon-ic-arrow-left:before {
  content: "\e917";
}
.rmv-icon-ic-arrow-right:before {
  content: "\e918";
}
.rmv-icon-ic-arrow-down:before {
  content: "\e919";
}
.rmv-icon-ic-arrow-up:before {
  content: "\e91f";
}
.rmv-icon-ic-download:before {
  content: "\e91a";
}
.rmv-icon-attachment:before {
  content: "\e91b";
}
.rmv-icon-deals-beta:before {
  content: "\e95e";
}
.rmv-icon-deal-projected-close-date:before {
  content: "\e95d";
}
.rmv-icon-deal-account:before {
  content: "\e958";
}
.rmv-icon-deal-amount:before {
  content: "\e959";
}
.rmv-icon-deal-contact:before {
  content: "\e95a";
}
.rmv-icon-deal-created-date:before {
  content: "\e95b";
}
.rmv-icon-deal-closed-date:before {
  content: "\e95c";
}
.rmv-icon-deals:before {
  content: "\e957";
}
.rmv-icon-drag:before {
  content: "\e956";
}
.rmv-icon-upload-file:before {
  content: "\e955";
}
.rmv-icon-accounts-type:before {
  content: "\e954";
}
.rmv-icon-enter:before {
  content: "\e953";
}
.rmv-icon-select-message:before {
  content: "\e952";
}
.rmv-icon-seen-message:before {
  content: "\e94f";
}
.rmv-icon-ic-archive-multiple:before {
  content: "\e950";
}
.rmv-icon-select-messages:before {
  content: "\e951";
}
.rmv-icon-invoice:before {
  content: "\e94e";
}
.rmv-icon-product-order:before {
  content: "\e94c";
}
.rmv-icon-refresh:before {
  content: "\e94d";
}
.rmv-icon-route-note:before {
  content: "\e94b";
}
.rmv-icon-copy-content:before {
  content: "\e94a";
}
.rmv-icon-send-email:before {
  content: "\e949";
}
.rmv-icon-contact-support:before {
  content: "\e948";
}
.rmv-icon-meeting:before {
  content: "\e947";
}
.rmv-icon-dollar-sign:before {
  content: "\e946";
}
.rmv-icon-add-tag:before {
  content: "\e938";
}
.rmv-icon-assign-user:before {
  content: "\e939";
}
.rmv-icon-delete-tags:before {
  content: "\e93a";
}
.rmv-icon-edit-category:before {
  content: "\e93b";
}
.rmv-icon-remove:before {
  content: "\e93c";
}
.rmv-icon-save-as:before {
  content: "\e936";
}
.rmv-icon-start-endpoint:before {
  content: "\e92b";
}
.rmv-icon-sort-icon-2:before {
  content: "\e926";
}
.rmv-icon-sort-icon:before {
  content: "\e925";
}
.rmv-icon-menu-2:before {
  content: "\e9bd";
}
.rmv-icon-copy:before {
  content: "\e92c";
}
