@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

@function unicode($str) {
  @return unquote('"') + $str + unquote('"');
}

// Insert icon in pseudo element from icon-fonts
@mixin pseudo-icon($icon-name, $size) {
  content: unicode($icon-name);
  font: {
    @if type-of($size) == 'number' and unitless($size) {
      size: toRem($size);
    } @else {
      size: $size;
    }
    family: var(--icon-font-family);
  }
  line-height: 1;
}

// Retrieve color Sass maps
@function clr($key: 'primary') {
  @return map-get($colors, $key);
}
