@import "src/assets/scss/functions";

.rmv-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  &__th {
    padding: toRem(12) toRem(4);
    color: var(--neutral-color);
    font-size: toRem(14);
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;

    &.--camel-case {
      text-transform: capitalize;
    }

    &:first-child {
      padding-left: toRem(24);
    }

    &[sortable] {
      position: relative;
      cursor: pointer;
      user-select: none;

      &:after {
        content: ' ';
        display: inline-block;
        width: toRem(12);
        margin-top: toRem(-6);
        margin-left: toRem(10);
      }

      &.sort {
        &:after {
          @include pseudo-icon('\e925', toRem(12));
        }
      }
      &.asc {
        &:after {
          @include pseudo-icon('\e91f', toRem(12));
        }
      }
      &.desc {
        &:after {
          @include pseudo-icon('\e919', toRem(12));
        }
      }
    }
  }

  &__tr {
    &:nth-of-type(odd) {
      background-color: var(--table-row-odd);
    }

    .rmv-table__td {
      padding: toRem(12) toRem(4);
      border-top: toRem(1) solid var(--neutral-color-2);
      font-size: toRem(14);

      &:first-child {
        padding-left: toRem(24);
        border-left: toRem(1) solid var(--neutral-color-2);
      }

      &:last-child {
        padding-right: toRem(24);
        border-right: toRem(1) solid var(--neutral-color-2);
      }
    }

    &:first-child {
      .rmv-table__td {
        &:first-child {
          padding-left: toRem(24);
          border-radius: toRem(8) 0 0 0;
          border-left: toRem(1) solid var(--neutral-color-2);
        }

        &:last-child {
          padding-right: toRem(24);
          border-radius: 0 toRem(8) 0 0;
          border-right: toRem(1) solid var(--neutral-color-2);
        }
      }
    }

    &:last-child {
      .rmv-table__td {
        border-bottom: toRem(1) solid var(--neutral-color-2);

        &:first-child {
          padding-left: toRem(24);
          border-radius: 0 0 0 toRem(8);
          border-left: toRem(1) solid var(--neutral-color-2);
        }

        &:last-child {
          padding-right: toRem(24);
          border-radius: 0 0 toRem(8) 0;
          border-right: toRem(1) solid var(--neutral-color-2);
        }
      }
    }
  }

  &__td {
    &.--top-align {
      vertical-align: top;
    }
  }
}
