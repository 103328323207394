@import "src/assets/scss/functions";

.rmv-radio-group {
  &__input {
    display: none;
  }

  &__item {
    display: inline-flex;
    align-items: center;

    padding: 0 toRem(3);

    border: none;

    &.--disabled {
      pointer-events: none;

      .rmv-radio-group__round {
        border: 1px solid var(--border-color);

        &:after {
          background-color: var(--border-color);
        }
      }

      .rmv-radio-group__label {
        color: var(--border-color);
      }
    }
  }

  &__input {
    &:checked + .rmv-radio-group__round {
      &:after {
        visibility: visible;
      }
    }
  }

  &__round {
    position: relative;
    display: inline-block;
    width: toRem(16);
    height: toRem(16);
    border: 1px solid var(--primary-color);
    border-radius: 50%;
    margin-right: toRem(12);

    &:after {
      content: '';
      visibility: hidden;
      position: absolute;
      display: inline-block;
      width: toRem(10);
      height: toRem(10);
      top: toRem(2);
      left: toRem(2);
      background-color: var(--primary-color);
      border-radius: 50%;
    }
  }
}
