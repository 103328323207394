@import "src/assets/scss/functions";

.rmv-tabs {
  &__item {
    border-radius: toRem(8) toRem(8) 0 0;

    &.--active {
      background: var(--primary-color-light);

      .rmv-tabs__link {
        color: var(--tab-active-text-color);
      }
    }
  }

  &__link {
    padding: toRem(8) toRem(24);
    color: var(--base-font-color);
    font-size: toRem(18);

    &:hover, &:focus, &:active {
      color: inherit;
    }
  }
}

.rmv-tabs-daily-route {
  height: toRem(48);

  border: toRem(1) solid var(--neutral-color-2);
  border-radius: toRem(8);

  overflow: hidden;

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;

    &.active {
      background: var(--primary-color);

      .rmv-tabs-daily-route__icon {
        color: var(--light-text-color);
      }
    }

    .rmv-tabs-daily-route__icon {
      color: var(--neutral-color);
    }
  }

  &__item {
    height: 100%;
  }
}
