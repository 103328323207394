@import "functions";

.rmv-heading-1 {
  font-size: toRem(24);
  font-weight: 700;
  line-height: toRem(33);
}

.rmv-heading-2 {
  font-size: toRem(18);
  font-weight: bold;
  line-height: toRem(24);
}

.rmv-body {
  font-size: toRem(16);
  font-weight: 400;
  line-height: toRem(22);
}

.rmv-large-text {
  font-size: toRem(18);
  font-weight: 400;
  line-height: toRem(24);
}

.rmv-middle-text {
  font-size: toRem(14);
  font-weight: 400;
  line-height: toRem(17);
}

.rmv-small-text {
  font-size: toRem(12);
  font-weight: 400;
  line-height: toRem(17);
}

.rmv-text-bold {
  font-weight: 600;
}

.rmv-text-dots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rmv-text-nowrap {
  white-space: nowrap;
}

.rmv-text-wrap {
  white-space: normal;
}

.rmv-text-neutral-color {
  color: var(--neutral-color);
}
