@import "src/assets/scss/functions";

.rmv-timepicker {
  fieldset {
    padding: 0 toRem(16);
  }

  .ngb-tp {
    justify-content: center;
  }

  .btn-link {
    color: var(--primary-color);

    &:active, &:focus {
      outline: none !important;
    }
  }

  .ngb-tp-input {
    &:active, &:focus {
      outline: none !important;
    }
  }

  .ngb-tp-meridian {
    .btn-outline-primary {
      color: var(--primary-color);
      border-color: var(--primary-color);

      &:active, &:focus, &:hover {
        box-shadow: none;
        outline: none;
        background: none;
      }
    }
  }
}
