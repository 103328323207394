@import 'src/assets/scss/functions';

.rmv-mention-tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 toRem(10);
  border-radius: toRem(26);
  background-color: var(--primary-color);
  color: var(--light-text-color);
  font-size: toRem(14);
  height: toRem(22);
}

.rmv-mention-input {
  display: inline;
  width: auto;
  border: none;
  background-color: var(--neutral-color-2);
  border-radius: toRem(6);
  color: var(--primary-color-3);

  &:active, &:focus {
    box-shadow: none;
    border: none;
    outline: none;
  }
}
