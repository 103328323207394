.rmv-group-buttons {}

.rmv-group-button {
  height: toRem(45);
  padding: 0 toRem(10);
  background: #fff;
  font-size: toRem(14);
  font-weight: bold;
  border: toRem(1) solid var(--neutral-color);
  color: var(--neutral-color);

  &:first-child {
    border-top-left-radius: toRem(8);
    border-bottom-left-radius: toRem(8);
  }

  &:last-child {
    border-top-right-radius: toRem(8);
    border-bottom-right-radius: toRem(8);
  }

  &.--selected {
    color: var(--light-text-color);
    background: var(--primary-color);
    border: toRem(1) solid var(--primary-color);
    border-top: none;
  }
}