@import "src/assets/scss/functions";

ngb-pagination {
  .pagination {
    margin: 0;
  }

  .page-item {
    margin: 0 toRem(4);

    &:first-child a {
      &:hover, &:active, &:focus {
        background: none !important;
      }
      span {
        display: none;
      }
      &:before {
        @include pseudo-icon('\e917', toRem(12));
        color: var(--neutral-color);
      }
    }
    &:last-child a {
      &:hover, &:active, &:focus {
        background: none !important;
      }
      span {
        display: none;
      }
      &:before {
        @include pseudo-icon('\e918', toRem(12));
        color: var(--neutral-color);
      }
    }

    .page-link {
      width: toRem(32);
      height: toRem(32);
      color: var(--base-font-color);
      font-size: toRem(14);
      border-radius: 50%;
      border: none;

      &:active, &:focus {
        box-shadow: none;
      }
    }

    &.active {
      .page-link {
        color: var(--base-font-color);
        background-color: var(--pagination-active);
      }
    }

    &.disabled {
      .page-link {
        //color: var(--neutral-color);
        opacity: .32;
      }
    }
  }
}
