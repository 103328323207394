@import "src/assets/scss/functions";

.rmv-rounds {
  width: toRem(8);
  height: toRem(8);
  background-color: var(--neutral-color);
  border-radius: 50%;

  &.--middle {
    width: toRem(12);
    height: toRem(12);
  }

  &.--large {
    width: toRem(19);
    height: toRem(19);
  }
}
