@import "@ng-select/ng-select/themes/material.theme.css";
@import "functions";
@import "mixins";

.ng-select {
  padding: 0;

  .ng-dropdown-panel.ng-select-bottom {
    top: calc(100%);
  }

  &.--compact {
    .ng-arrow-wrapper {
      right: toRem(10);
    }
    .ng-value {
      padding-right: toRem(10);
    }
  }

  .ng-select-container {
    border: 1px solid var(--input-border-color);
    border-radius: 6px;
    min-height: toRem(48);
    height: toRem(48);

    &:after {
      border: none;
    }
  }

  &.ng-select-focused, .ng-has-value {
    .ng-placeholder {
      top: toRem(22);
      transform: translateY(-.6em) scale(0.75) perspective(toRem(100)) translateZ(toRem(0.001)) !important;
    }
  }

  &.submitted {
    &.ng-invalid {
      .ng-select-container {
        border: 1px solid var(--error-color);
      }
    }
  }

  .ng-arrow-wrapper {
    right: toRem(24);
    bottom: toRem(12) !important;

    .ng-arrow {
      width: auto !important;
      height: auto !important;

      border: none;

      &:after {
        @include pseudo-icon('\e919', 14);
        color: var(--neutral-color);
      }
    }
  }

  &.ng-select-clearable {
    .ng-arrow-wrapper {
      right: toRem(5);
    }
  }

  .ng-clear-wrapper {
    right: toRem(5);
    bottom: toRem(9) !important;
    span {
      color: var(--neutral-color) !important;
      font-size: 1.4rem !important;
    }
  }

  .ng-placeholder {
    position: absolute;
    top: 50%;
    left: toRem(20);
    margin-top: toRem(-11);
    color: var(--neutral-color) !important;
  }

  .ng-value {
    flex: 1;
    margin-left: toRem(20);
    padding-right: 40px;
    //white-space: normal !important;
  }

  .ng-input {
    padding-left: toRem(20);
    bottom: 0.1rem !important;
  }

  .ng-spinner-loader {
    position: absolute !important;
    top: 50%;
    right: toRem(22);
    margin-top: toRem(-8.5);
    border-left-color: var(--primary-color) !important;

    & + .ng-arrow-wrapper {
      display: none;
    }
  }

  &.rmv-select-pagination {
    .ng-select-container {
      width: toRem(122);
      min-height: toRem(44);
      height: toRem(44);
    }

    .ng-arrow-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: toRem(28);
      top: 0;
      right: 0;
      height: 100%;
      background: var(--select-arrow-wrapper-color);

      .ng-arrow {
        &:after {
          content: '\e90c';
          font-size: toRem(8);
        }
      }
    }

    .ng-value-container {
      padding: 0;
    }
  }

  &.ng-select-multiple {
    .ng-arrow-wrapper {
      bottom: toRem(6) !important;
    }

    .ng-select-container {
      height: auto;

      .ng-value-container {
        .ng-value {
          flex: none;
          margin: toRem(2) 0 toRem(2) toRem(6);
          padding: toRem(2) toRem(6);
          background-color: var(--primary-color);
          border-radius: toRem(26);
        }
      }
    }
  }
}

.ng-dropdown-panel {
  .ng-option-selected {
    color: var(--primary-color) !important;
  }

  .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background: none;
  }
}

.rmv-tag-select {
  .ng-arrow-wrapper {
    width: toRem(30);
    right: 0;
    bottom: toRem(6) !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    line-height: normal;
  }

  &.ng-select-multiple {
    .ng-select-container {
      height: auto;
      max-height: toRem(48);

      .ng-value-container {
        .ng-value-icon {
          color: var(--tag-remove-icon-color) !important;
          font-size: toRem(26);
          margin: 0;
          padding-right: 0 !important;
        }

        .ng-value {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          margin: toRem(2) 0 toRem(2) toRem(6);
          padding: 0 toRem(2) 0 toRem(4);
          background-color: var(--neutral-color);
          border-radius: toRem(5);
          font-size: toRem(14);
          max-width: 100%;
          white-space: initial !important;
          justify-content: space-around;
        }
      }
    }

    .ng-option {
      padding-top: toRem(4);
      padding-bottom: toRem(4);
      border-bottom: toRem(1) solid var(--neutral-color-2);
    }

    .ng-input {
      margin-left: toRem(5);
      padding-left: toRem(10);
      height: toRem(19.5);
    }

    .ng-dropdown-panel.ng-select-bottom {
      top: calc(100%);
    }
  }
}

.ng-select-ng-value {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
