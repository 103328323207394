@import 'src/assets/scss/functions';

.rmv-typeahead-mention-popup {
  border-color: var(--neutral-color-2);
  background-color: var(--light-background-color);
  padding: toRem(8);

  .dropdown-item {
    padding: toRem(5);

    &:not(:last-child) {
      border-bottom: toRem(1) solid var(--neutral-color-2);
    }

    &.active, &:hover {
      color: inherit;
      background: var(--neutral-color-3);
    }
  }
}
