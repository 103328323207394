.rmv-notify {
  &.toast-success {
    background-color: var(--primary-color);
  }

  &.toast-error {
    background-color: var(--error-color);
  }

  .toast-message {
    color: var(--light-text-color);
  }

  &.--mention {
    &.toast-success {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAyMCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNyAwSDNDMS4zNDMxNSAwIDAgMS4zNDMxNSAwIDNWMTNDMCAxNC42NTY5IDEuMzQzMTUgMTYgMyAxNkgxN0MxOC42NTY5IDE2IDIwIDE0LjY1NjkgMjAgMTNWM0MyMCAxLjM0MzE1IDE4LjY1NjkgMCAxNyAwWk0zIDJIMTdDMTcuNTUyMyAyIDE4IDIuNDQ3NzIgMTggM0wxMCA3Ljg4TDIgM0MyIDIuNDQ3NzIgMi40NDc3MiAyIDMgMlpNMTcgMTRDMTcuNTUyMyAxNCAxOCAxMy41NTIzIDE4IDEzVjUuMjhMMTAuNDggOS44NUMxMC4xNzA2IDEwLjAyODYgOS43ODk0IDEwLjAyODYgOS40OCA5Ljg1TDIgNS4yOFYxM0MyIDEzLjU1MjMgMi40NDc3MiAxNCAzIDE0SDE3WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
    }
  }
}
