@import "src/assets/scss/functions";

.rmv-drop-list {
  max-width: 100%;
  display: block;
  background: white;
  overflow: hidden;
}

.rmv-drop-list-item {
  padding: toRem(2) toRem(5);
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  background: white;
  position: relative;
  z-index: 99999 !important;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: toRem(4);
  box-shadow: 0 toRem(3) toRem(1) toRem(-1) rgba(0, 0, 0, 0.14),
  0 toRem(4) toRem(5) toRem(1) rgba(0, 0, 0, 0.14),
  0 toRem(1) toRem(6) toRem(2) rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.rmv-drop-list-item:last-child {
  border: none;
}

.rmv-drop-list.cdk-drop-list-dragging .rmv-drop-list-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
