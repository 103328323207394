@import "src/assets/scss/functions";

.rmv-slider {
  height: toRem(2) !important;

  .noUi-handle {
    box-shadow: none;
  }

  .noUi-handle::before,
  .noUi-handle::after {
    display: none;
  }

  .noUi-handle {
    width: toRem(24) !important;
    height: toRem(24) !important;
    top: toRem(-12) !important;
    right: toRem(-12) !important;
    border: none;
    border-radius: 50%;
    background-color: var(--ui-slider-handle-color);

    &:after {
      content: '';
      display: block;
      width: toRem(8);
      height: toRem(8);
      background-color: var(--primary-color);
      border-radius: 50%;
      transform: translate(toRem(-9), toRem(2));
    }
  }

  &.noUi-target {
    background-color: var(--neutral-color);
    border: none;
  }

  .noUi-tooltip {
    padding: toRem(13) toRem(20);
    color: var(--base-font-color);
    font-size: toRem(16);
    line-height: toRem(22);
    border: toRem(1) solid var(--neutral-color-2);
    border-radius: toRem(6);
  }

  .noUi-connect {
    background: var(--primary-color) !important;
  }

  .noUi-marker {
    display: none;
  }

  .noUi-value {
    font-size: toRem(14);
    color: var(--dark-text-color);
  }

  .noUi-pips-horizontal {
    padding: 0;
    height: toRem(40);
  }
}

