@import 'src/assets/scss/functions';

.rmv-link {
  color: var(--link-color);

  &:hover {
    color: #0a58ca;
  }

  &--base {
    color: var(--base-font-color);
    text-decoration: none;
  }

  &--no-style {
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }

  &--icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: toRem(36);
    height: toRem(36);
  }

  &--disabled {
    pointer-events: none;
    cursor: default;
  }
}

.rmv-link-icon {
  font-size: toRem(20);
}
