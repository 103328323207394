@import "src/assets/scss/functions";

.rmv-date-info-icons {
  &__start {
    color: var(--base-font-color);
    font-size: toRem(20);
  }

  &__end {
    color: var(--base-font-color);
    font-size: toRem(20);
  }

  &__line {
    border-left: toRem(2) dashed var(--base-font-color);

  }
}
