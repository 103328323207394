@import "src/assets/scss/functions";

.rmv-datepicker {
  width: 100%;
  border: none;
  overflow: hidden;

  &.--only-week-1 {
    .ngb-dp-week {
      &:not(:nth-child(2)) {
        display: none;
      }
    }
  }

  &.--only-week-2 {
    .ngb-dp-week {
      &:not(:nth-child(3)) {
        display: none;
      }
    }
  }

  &.--only-week-3 {
    .ngb-dp-week {
      &:not(:nth-child(4)) {
        display: none;
      }
    }
  }

  &.--only-week-4 {
    .ngb-dp-week {
      &:not(:nth-child(5)) {
        display: none;
      }
    }
  }

  &.--only-week-5 {
    .ngb-dp-week {
      &:not(:nth-child(6)) {
        display: none;
      }
    }
  }

  &.--only-week-6 {
    .ngb-dp-week {
      &:not(:nth-child(7)) {
        display: none;
      }
    }
  }

  .ngb-dp-header, .ngb-dp-weekdays {
    background-color: var(--light-background-color);
  }

  .ngb-dp-months {
    margin-top: toRem(10);
  }

  .ngb-dp-weekdays {
    border: toRem(1) solid var(--neutral-color-3);
  }

  .ngb-dp-arrow-btn {
    color: var(--neutral-color);
  }

  .ngb-dp-month-name {
    color: var(--base-font-color);
    font-size: toRem(18);
    font-weight: 700;
    background-color: var(--light-background-color);
  }

  .ngb-dp-weekday {
    flex: 1;
    height: toRem(22);
    color: var(--neutral-color);
    font-size: toRem(14);
    font-style: normal;
    border: toRem(1) solid var(--neutral-color-3);
  }

  .ngb-dp-week {
    padding: 0 !important;
    border: none;
    //border-top: toRem(1) solid var(--neutral-color-3);
    border-bottom: toRem(1) solid var(--neutral-color-3);
    border-radius: 0;

    &:first-child {
      border-radius: toRem(3) toRem(3) 0 0;
    }

    &:last-child {
      //border-radius: 0 0 toRem(3) toRem(3);
      border-bottom: none;
    }

    .ngb-dp-weekday {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: toRem(4);
      border: none;
      border-right: toRem(1) solid var(--neutral-color-3);

      &:last-child {
        border: none;
      }
    }
  }

  .ngb-dp-day {
    width: toRem(48);
    height: toRem(44);
    border-right: toRem(1) solid var(--neutral-color-3);

    &:last-child {
      border: none;
    }
  }

  ngb-datepicker-month {
    border: toRem(1) solid var(--neutral-color-3);
    border-radius: toRem(10);
  }

  .btn-light {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-right: toRem(4);
    color: var(--base-font-color);
    font-size: toRem(14);

    &.bg-primary {
      background-color: var(--primary-color) !important;
      border-radius: 0 !important;
      outline: toRem(1) solid var(--primary-color-3);
    }
  }
}
