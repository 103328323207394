@import 'src/assets/scss/functions';

.ql-formats {
  button,
  .ql-picker {
    &[class^='ql-'], &[title='Show HTML source'] {
      position: relative;

      &::after {
        border-radius: toRem(5);
        white-space: nowrap;
        position: absolute;
        display: none;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, -10px);
        background-color: var(--neutral-color);
        color: #fff;
        padding: toRem(5);
        min-width: 100%;
        text-align: center;
      }

      &:hover {
        &::after,
        &::before {
          display: block;
        }
      }
    }

    &.ql-bold::after {
      content: 'Bold';
    }

    &.ql-italic::after {
      content: 'Italic';
    }

    &.ql-underline::after {
      content: 'Underline';
    }

    &.ql-strike::after {
      content: 'Strikethrough';
    }

    &.ql-font-style::after {
      content: 'Font Style';
    }

    &.ql-align {
      &[value='']::after {
        content: 'Align Left';
      }

      &[value='center']::after {
        content: 'Align Center';
      }

      &[value='right']::after {
        content: 'Align Right';
      }

      &[value='justify']::after {
        content: 'Align Justify';
      }
    }

    &.ql-list {
      &[value='bullet']::after {
        content: 'Bullet List';
      }

      &[value='ordered']::after {
        content: 'Numbered List';
      }
    }

    &.ql-blockquote::after {
      content: 'Blockquote';
    }

    &.ql-code-block::after {
      content: 'Code Block';
    }

    &[aria-label='header: 1']::after {
      content: 'Header 1';
    }

    &[aria-label='header: 2']::after {
      content: 'Header 1';
    }

    &[aria-label='script: sub']::after {
      content: 'Subscript';
    }

    &[aria-label='script: super']::after {
      content: 'Superscript';
    }

    &[aria-label='indent: -1']::after {
      content: 'Outdent';
    }

    &[aria-label='indent: +1']::after {
      content: 'Indent';
    }

    &[aria-label='direction: rtl']::after {
      content: 'Text Direction';
    }

    &.ql-size::after {
      content: 'Size';
    }

    &.ql-header::after {
      content: 'Header';
    }

    &.ql-background::after {
      content: 'Background';
    }

    &.ql-link::after {
      content: 'Link';
    }

    &.ql-clean::after {
      content: 'Clean Markup';
    }

    &.ql-color::after {
      content: 'Text Color';
    }

    &.ql-font::after {
      content: 'Font Style';
    }

    &.ql-align::after {
      content: 'Align';
    }

    &.ql-video::after {
      content: 'Video';
    }

    &[title='Show HTML source']::after {
      content: 'HTML Source';
    }
  }
}
