/* You can add global styles to this file, and also import other style files */

@import "bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

@import 'assets/scss/functions';
@import 'assets/scss/text';
@import 'assets/scss/buttons';
@import 'assets/scss/link';
@import 'assets/scss/slider';
@import 'assets/scss/notify';
@import 'assets/scss/dropdown';
@import 'assets/scss/pagination';
@import 'assets/scss/table';
@import 'assets/scss/rounds';
@import 'assets/scss/ng-select';
@import 'assets/scss/radio-group';
@import 'assets/scss/tabs';
@import 'assets/scss/datepicker';
@import 'assets/scss/timepicker';
@import 'assets/scss/date-info-icons';
@import 'assets/scss/drop-list';
@import 'assets/scss/typehead';
@import 'assets/scss/mention-tag';
@import 'assets/scss/typeahead-mention-popup';
@import 'assets/scss/quill-tooltip';
@import 'assets/scss/group-button';
@import 'assets/icomoon-v1.0/style.css';
@import 'node_modules/flag-icons/css/flag-icons.min.css';

:root {
  --primary-color: #06BB79;
  --primary-color-light: #B8EBD9;
  --primary-color-2: #0041FF;
  --primary-color-3: #1E2E50;
  --semantic-color: #FF9B15;
  --semantic-color-2: #9A45FF;
  --semantic-color-3: #FF5858;
  --base-font-color: var(--primary-color-3);
  --gradient-from-color: #06A48F;
  --gradient-to-color: #05D163;
  --neutral-color: #9AA0BA;
  --neutral-color-2: #E4E6EF;
  --neutral-color-3: #F2F4F8;
  --neutral-color-4: #F8F9FB;
  --border-color: var(--neutral-color);
  --error-color: var(--semantic-color-3);
  --button-text-color: var(--neutral-color);
  --button-border-color: var(--primary-color-3);
  --button-disabled-color: var(--neutral-color-2);
  --input-text-color: var(--neutral-color);
  --input-border-color: var(--neutral-color-2);
  --light-text-color: #fff;
  --dark-text-color: #000;
  --light-background-color: #fff;
  --dark-background-color: #fff;
  --dark-border-color: #fff;
  --ui-slider-handle-color: var(--primary-color-light);
  --pagination-color: var(--primary-color);
  --selected-menu-color: #51CFA1;
  --icon-font-family: 'icomoon' !important;
  --pagination-active: #F8F9FB;
  --table-row-odd: #F8F9FB;
  --select-arrow-wrapper-color: #F8F9FB;
  --context-menu-item-color: #F8F9FB;
  --link-color: #06BB79;
  --tag-remove-icon-color: #5F6582;
  --start-location-color: #06BB79;
  --end-location-color: #FF5858;
  --tab-active-text-color: var(--primary-color-3);
  --amount-color: #06BB79;
}

[data-theme=dark-theme] {
  --primary-color: #0041FF;
  --gradient-from-color: #0f4bff;
  --gradient-to-color: #275eff;
  --light-text-color: #575757;
  --dark-background-color: #2c2b2b;
  --selected-menu-color: #275eff;
  --tab-active-text-color: var(--light-text-color);
}

[data-theme=blue-theme] {
  --primary-color: #5092DB;
  --primary-color-light: #73B0F3;
  --pagination-color: #73B0F3;
  --gradient-from-color: #5092DB;
  --gradient-to-color: #73B0F3;
  --selected-menu-color: #73B0F3;
  --tab-active-text-color: var(--light-text-color);
}

[data-theme=royal-blue-theme] {
  --primary-color: #084A9E;
  --primary-color-light: #235fab;
  --pagination-color: #084A9E;
  --gradient-from-color: #084A9E;
  --gradient-to-color: #235fab;
  --selected-menu-color: #235fab;
  --tab-active-text-color: var(--light-text-color);
}

[data-theme=dark-green-theme] {
  --primary-color: #113D3C;
  --primary-color-light: #1f504e;
  --pagination-color: #1E2E50;
  --gradient-from-color: #113D3C;
  --gradient-to-color: #1f504e;
  --selected-menu-color: #1f504e;
  --tab-active-text-color: var(--light-text-color);
}

[data-theme=beige-theme] {
  --primary-color: #F5F5DD;
  --primary-color-light: #dcdcbe;
  --pagination-color: #F5F5DD;
  --gradient-from-color: #F5F5DD;
  --gradient-to-color: #dcdcbe;
  --light-text-color: #2c2b2b;
  --selected-menu-color: #dcdcbe;
}

[data-theme=lavender-theme] {
  --primary-color: #B47DDB;
  --primary-color-light: #B47DDB;
  --pagination-color: #B47DDB;
  --gradient-from-color: #B47DDB;
  --gradient-to-color: #d5acf3;
  --selected-menu-color: #B47DDB;
  --tab-active-text-color: var(--light-text-color);
}

[data-theme=hot-pink-theme] {
  --primary-color: #FC0FBF;
  --primary-color-light: #ff4ed1;
  --pagination-color: #FC0FBF;
  --gradient-from-color: #FC0FBF;
  --gradient-to-color: #ff4ed1;
  --selected-menu-color: #ff4ed1;
  --tab-active-text-color: var(--light-text-color);
}

[data-theme=dark-red-theme] {
  --primary-color: #C24A4C;
  --primary-color-light: #b77475;
  --pagination-color: #CD6461;
  --gradient-from-color: #C24A4C;
  --gradient-to-color: #b77475;
  --selected-menu-color: #b77475;
  --tab-active-text-color: var(--light-text-color);
}

[data-theme=red-theme] {
  --primary-color: #F33636;
  --primary-color-light: #f65050;
  --pagination-color: #F33636;
  --gradient-from-color: #F33636;
  --gradient-to-color: #f65050;
  --selected-menu-color: #f65050;
  --tab-active-text-color: var(--light-text-color);
}

[data-theme=orange-theme] {
  --primary-color: #F8931D;
  --primary-color-light: #f5a548;
  --pagination-color: #F8931D;
  --gradient-from-color: #F8931D;
  --gradient-to-color: #f5a548;
  --selected-menu-color: #f5a548;
  --tab-active-text-color: var(--light-text-color);
}

[data-theme=yellow-theme] {
  --primary-color: #FEF200;
  --primary-color-light: #eee76a;
  --pagination-color: #FEF200;
  --gradient-from-color: #FEF200;
  --gradient-to-color: #eee76a;
  --light-text-color: #2c2b2b;
  --selected-menu-color: #eee76a;
}

[data-theme=aquamarine-theme] {
  --primary-color: #7EFFD4;
  --primary-color-light: #66f5c5;
  --pagination-color: #7EFFD4;
  --gradient-from-color: #7EFFD4;
  --gradient-to-color: #66f5c5;
  --button-text-color: #1E2E50;
  --light-text-color: #2c2b2b;
  --selected-menu-color: #66f5c5;
}

[data-theme=lime-theme] {
  --primary-color: #BBFF00;
  --primary-color-light: #cdf859;
  --pagination-color: #BBFF00;
  --gradient-from-color: #BBFF00;
  --gradient-to-color: #cdf859;
  --light-text-color: #2c2b2b;
  --selected-menu-color: #cdf859;
}

[data-theme=brown-theme] {
  --primary-color: #764C24;
  --primary-color-light: #885a2e;
  --pagination-color: #764C24;
  --gradient-from-color: #764C24;
  --gradient-to-color: #885a2e;
  --selected-menu-color: #885a2e;
  --tab-active-text-color: var(--light-text-color);
}

[data-theme=gray-theme] {
  --primary-color: #7F7F7F;
  --primary-color-light: #a8a7a7;
  --pagination-color: #7F7F7F;
  --gradient-from-color: #7F7F7F;
  --gradient-to-color: #a8a7a7;
  --selected-menu-color: #a8a7a7;
  --tab-active-text-color: var(--light-text-color);
}

[data-theme=dark-gray-theme] {
  --primary-color: #454545;
  --primary-color-light: #757373;
  --pagination-color: #454545;
  --gradient-from-color: #454545;
  --gradient-to-color: #757373;
  --selected-menu-color: #757373;
  --tab-active-text-color: var(--light-text-color);
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  color: var(--base-font-color);
  overflow: hidden;
}

.margin-0 {
  margin: 0 !important;
}

.modal-dialog {
  min-width: toRem(764);
}

.modal-content {
  padding: toRem(24);
  border-radius: toRem(10);
  border: none;
  box-shadow: 0 2px 9px rgba(30, 46, 80, 0.25);
}

.modal-backdrop {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(6px);
  opacity: 1 !important;
}

.rmv-modal-small {
  width: toRem(392);
  min-width: auto;
}

.rmv-modal-middle {
  width: toRem(800);
}

.rmv-modal-recurring-stops {
  width: toRem(1000);
  min-width: auto;
  max-width: toRem(1000);
}

.rmv-modal-import-accounts {
  width: toRem(1200);
  min-width: auto;
  max-width: toRem(1200);
}

.rmv-window-import-accounts {
  display: flex !important;
  flex-direction: column;
  padding: toRem(20);
  width: 100%;
}

.rmv-modal-calendar {
  width: toRem(380);
  min-width: auto;
  max-width: toRem(380);
}

.rmv-window-full {
  display: flex !important;
  flex-direction: column;
  padding: toRem(20);

  .modal-dialog {
    margin: 0;
    height: 100%;
  }

  .modal-content {
    height: 100%;
  }
}

.rmv-modal-full {
  width: 100%;
  min-width: auto;
  max-width: none;
}

.rmv-modal-window-confirmation {
  z-index: 1071;
}

.rmv-modal-auto-width {
  min-width: auto;
}

.rmv-modal-window-responsive {
  @media screen and (max-width: 992px) {
    padding: toRem(16);
    max-width: 100vw;
    min-width: 100vw;
    max-height: 100vh;
    overflow: scroll;
  }
}

.rmv-modal-select-plan {
  @media screen and (max-width: 992px) {
    margin: 0;
    max-width: 100%;
    min-width: 100%;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.primary-color {
  color: var(--primary-color);
}

.neutral-color {
  color: var(--neutral-color);
}

.rmv-color-picker {
  .IroColorPicker {
    justify-content: center;
  }
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.rmv-label {
  color: var(--neutral-color);
}

.rmv-nowrap {
  white-space: nowrap;
}

.success {
  color: var(--primary-color);
}

.warning {
  color: var(--semantic-color);
}

.danger {
  color: var(--error-color);
}

.rmv-hidden {
  display: none;
}

.rmv-validation-message {
  color: var(--error-color);
  font-size: toRem(12);
}

.rmv-square {
  width: toRem(48);
  height: toRem(48);
}

.popover {
  max-width: max-content;
}

.rmv-daily-route-tab-content {
  .tab-pane {
    height: 100%;
  }
}

.rmv-icon-middle-size {
  width: 1rem;
  height: 1rem;
}

.rmv-text-dots {
  @include text-dot;
}

.rmv-html-editor {
  height: toRem(300);
}

.modal-dialog {
  @media screen and (max-width: 992px) {
    max-width: 100vw;
  }
}

.firebase-emulator-warning {
  display: none;
}

.rmv-scroll-horizontal-deals {
  --scrollbar-thickness: 10 !important;
  .ng-scroll-content {
    height: 100%;
  }
}

.rmv-scroll-vertical-deals {
  --scrollbar-thickness: 5;
  .ng-scroll-content {
    min-height: calc(100vh - 17.9375rem) !important;
    max-width: 100%;
    padding: toRem(8) toRem(16) !important;
  }
}

.rmv-flex-1 {
  flex: 1 !important;
}

.rmv-themed-track {
  .ng-scrollbar-thumb {
    background-color: var(--primary-color) !important;
  }
}
