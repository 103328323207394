@import "src/assets/scss/functions";

.rmv-dropdown {
  .dropdown-menu {
    padding: 0;
    border-radius: toRem(10);
    border: none;
    box-shadow: 0 2px 9px rgba(30, 46, 80, 0.25);
  }
}

.dropdown-toggle {
  &:after {
    display: none;
  }
}
